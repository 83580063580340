import { SiteSupportsContentSyncStatus } from "@modules/graphql/types"

import { ContentLoaderSiteSupportContentSyncQuery } from "@modules/graphql/queries.generated"

import { errorMessages } from "./index"

/**
 * Sets an error message that the site doesn't support content sync if it doesn't. Doesn't show the error though because we want to wait and see if Content Sync will work anyway. This is because we don't explicitly know of every source plugin that exists and wether or not they support content sync. So we wait to see if content sync fails and at that point this function will have set a message that content sync isn't supported if that's the case.
 */
export const handleSiteSupportsContentSync = ({
  apolloData,
  sourcePluginName,
  setErrorMessage,
  setCheckForContentSyncSupport,
}: {
  apolloData: ContentLoaderSiteSupportContentSyncQuery | undefined
  sourcePluginName: string
  setErrorMessage: (arg: string) => void
  setCheckForContentSyncSupport: (arg: boolean) => void
}) => {
  if (apolloData?.siteSupportsContentSync) {
    const { status, gatsby, sourcePlugin } = apolloData.siteSupportsContentSync
    const displayMessage: { [key: string]: Function | null } = {
      [SiteSupportsContentSyncStatus.Error]: () => {
        console.error(`siteSupportsContentSync server error`)
      },
      [SiteSupportsContentSyncStatus.Success]: () => {
        const messages: string[] = []
        /*
          explicityly checking for false because null can be returned which means
          that we don't know if the version is supported
        */
        if (gatsby === false) {
          messages.push(errorMessages.unsupportedGatsbyVersion)
        }
        /*
          explicitly checking for false because null can be returned which means
          that we don't know if the version is supported
        */
        if (sourcePlugin === false) {
          messages.push(
            errorMessages.unsupportedSourcePluginVersion(sourcePluginName)
          )
        }
        if (messages.length > 0) {
          messages.forEach(message => console.warn(message))
          setErrorMessage(messages.join(` `))
        }
      },
      [SiteSupportsContentSyncStatus.Unavailable]: null,
    }
    if (status && displayMessage[status]) {
      const msgFunc = displayMessage[status]
      if (msgFunc) {
        msgFunc()
      }
      setCheckForContentSyncSupport(false)
    }
  }
}
